import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "auth/session",
  initialState: {
    token: "",
    signedIn: false,
    id: "",
    isAuth:true
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true;
      state.token = action.payload;
      state.id = action.payload;
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false;
      state.token = "";
      state.id = "";
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsAuth:(state, {payload})=>{
      state.isAuth = payload;
    }
  },
});

export const { onSignInSuccess, onSignOutSuccess, setToken, setIsAuth } =
  sessionSlice.actions;

export default sessionSlice.reducer;
