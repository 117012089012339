import axios from "axios";
import { Notification, toast } from "components/ui";
import { baseURL } from "services/baseUrl";

const openNotification = (type, title, message) => {
  toast.push(
    <Notification title={title} type={type} duration={10000} closable>
      {message}{" "}
    </Notification>,
    {
      placement: "top-end",
    }
  );
};

export const messageErrorHttp = (res) => {
  if (res.response.status === 400) {
    return (
      <div className="alert alert-danger" role="alert">
        {openNotification(
          "danger",
          "Erro",
          "Já existe um registro com esse nome!"
        )}
      </div>
    );
  }

  if (res.response.status === 500) {
    return (
      <div className="alert alert-danger" role="alert">
        {openNotification(
          "danger",
          "Erro",
          "Estamos com problemas no servidor interno!"
        )}
      </div>
    );
  }
};

export const verificaCNPJ = async (cnpj) => {
  const verificaCnpj = await axios
    .get(`${baseURL}/Comercial/Empresa/${cnpj}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else if (res.status === 404) {
        return;
      }
    })
    .catch((err) => {
      return err;
    });

  if (verificaCnpj.cnpj === cnpj) {
    return (
      <div className="alert alert-danger" role="alert">
        {openNotification(
          "info",
          "Informação",
          "Já existe uma empresa cadastrada com esse CNPJ."
        )}
      </div>
    );
  }

  return;
};
