import axios from "axios";
import appConfig from "configs/app.config";
import store from "../store";
import { setIsAuth } from "store/auth/sessionSlice";

const unauthorizedCode = [401];

store.subscribe(listener)

function select(state) {  
  return state.auth.session.token
}

function listener() {
  const token = select(store.getState())
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response:{ status } } = error;
    if (unauthorizedCode.includes(status))
      store.dispatch(setIsAuth(false));
    return Promise.reject(error);
  }
);

export default BaseService;
