import { useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { persistor } from "../../store";
import Axios from "axios";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated()

  const { instance, accounts } = useMsal()

  const query = useQuery();

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    sessionStorage.clear();
    persistor.purge();
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const azureSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  const signOut = async () => {
    if (isAuthenticated) azureSignOut();
    handleSignOut();
    await apiSignOut();
  };

  const getToken = async () => {
    const request = {
      account: accounts[0],
      scopes: [ `${process.env.REACT_APP_AAD_SCOPE_ID}` ],
    }
    const silentToken = await instance.acquireTokenSilent(request)
    
    Axios.defaults.headers.common["Authorization"] = `Bearer ${silentToken}`;

    dispatch(onSignInSuccess(silentToken.accessToken));
    dispatch(setUser(
      {
        avatar: "",
        userName: accounts[0]?.name,
        authority: accounts[0]?.idTokenClaims.roles,
        email: accounts[0]?.username
      }
    ))
  }


return {
  authenticated: isAuthenticated,
  signIn,
  signUp,
  signOut,
  getToken,
};
}

export default useAuth;
